import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Hero from "../../components/Hero/Hero"
import SEO from "../../components/seo"
import moment from "moment"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../../components/VindiciaComponents"

const Events = ({ data }) => {
  const content = data.prismicEventsPag.data
  const eventsList = data.allPrismicEvents.edges

  return (
    <Layout>
      <SEO
        title={content.seo_title}
        keywords={content.seo_keywords}
        description={content.seo_description}
      />
      <Hero page={content.uid} data={content} />
      <section className="uk-section">
        <div className="uk-container uk-container-small">
          <div
            className="prismic-content uk-margin-large"
            dangerouslySetInnerHTML={{ __html: content.content.html }}
          />
        </div>
      </section>
      <section className="uk-section uk-padding-remove-top events-list">
        <div className="uk-container uk-container-small">
          <div
            className="uk-grid-column-medium uk-child-width-1-2@s uk-grid"
            data-uk-grid="true"
            data-uk-height-match="target: > div > .uk-card > div .uk-card-body"
          >
            {eventsList.map((event, i) => (
              <div key={`event-${i}`}>
                <div className="uk-position-relative">
                  <Image
                    src={event.node.data.banner.fluid.src}
                    srcSet={event.node.data.banner.fluid.srcSet}
                    alt={event.node.data.title.text}
                  />
                  <div className="uk-overlay uk-overlay-primary uk-position-bottom uk-text-left">
                    <p className="uk-h4">{event.node.data.title.text}</p>
                    <p className="news-link">
                      {moment(event.node.data.start_time).format("MMM D, YYYY")} -{" "}
                      {moment(event.node.data.end_time).format("MMM D, YYYY")} |{" "}
                      <Link to={`/company/events/${event.node.uid}`}>
                        Read more <span uk-icon="icon: arrow-right"></span>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Events)

export const eventsQuery = graphql`
  {
    prismicEventsPag {
      uid
      data {
        seo_title
        seo_keywords
        seo_description
        custom_page_title
        content {
          html
        }
        hero_title {
          html
          text
        }
        hero_text_alignment
        hero_banner_image {
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
          alt
        }
      }
    }
    allPrismicEvents(sort: { fields: data___start_time, order: DESC }, limit: 12) {
      edges {
        node {
          uid
          data {
            title {
              html
              text
            }
            start_time
            date_text_string
            cta
            end_time
            body {
              html
            }
            banner {
              alt
              copyright
              url
              fluid(maxWidth: 1400) {
                ...GatsbyPrismicImageFluid_noBase64
              }
              alt
            }
            event_url {
              target
              url
            }
          }
        }
      }
    }
  }
`
